<template>
  <Auth />
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <CardTotalBalance :is_admin="is_admin" />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <CardTotalOrderThisMonth :is_admin="is_admin" />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <CardTotalCODSuccessThisMonth :is_admin="is_admin" />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <CardTotalBill :is_admin="is_admin" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7 mb-lg">
            <ChartSales :is_admin="is_admin" />
          </div>
          <div class="col-lg-5">
            <carousel
              :items="[
                {
                  img: image2,
                  title: 'Get started with Argon',
                  description:
                    'There’s nothing I really wanted to do in life that I wasn’t able to get good at.',
                  icon: {
                    component: 'ni ni-camera-compact text-dark',
                    background: 'bg-white',
                  },
                },
                {
                  img: image1,
                  title: 'Faster way to create web pages',
                  description:
                    'That’s my skill. I’m not really specifically talented at anything except for the ability to learn.',
                  icon: {
                    component: 'ni ni-bulb-61 text-dark',
                    background: 'bg-white',
                  },
                },
                {
                  img: image3,
                  title: 'Share with us your design tips!',
                  description:
                    'Don’t be afraid to be wrong because you can’t learn anything from a compliment.',
                  icon: {
                    component: 'ni ni-trophy text-dark',
                    background: 'bg-white',
                  },
                },
              ]"
            />
          </div>
        </div>
        <div class="row mt-4">
          <OverviewBoxSaldo :clickable="clickable" :is_admin="is_admin" @viewdetailpackage="handleviewdetailpackage" />
        </div>
        <div class="row mt-4">
          <OverviewBoxOrder :clickable="clickable" :is_admin="is_admin" @viewdetailpackage="handleviewdetailpackage" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Auth from "@/components/auth/Auth";
import CardTotalBalance from "@/components/Overview/CardTotalBalance.vue";
import CardTotalOrderThisMonth from "@/components/Overview/CardTotalOrderThisMonth.vue";
import CardTotalCODSuccessThisMonth from "@/components/Overview/CardTotalCODSuccessThisMonth.vue";
import CardTotalBill from "@/components/Overview/CardTotalBill.vue";
import ChartSales from "@/components/Overview/ChartSales.vue";
import Carousel from "././components/Carousel.vue";
import OverviewBoxOrder from "@/components/SendPackages/OverviewBoxOrder.vue";
import OverviewBoxSaldo from "@/components/Finance/OverviewBoxSaldo.vue";

import image2 from "../../assets/img/img-2.jpg";
import image1 from "../../assets/img/img-1.jpg";
import image3 from "../../assets/img/img-3.jpg";


export default {
  name: "Statistics",
  components: {
    Auth,
    CardTotalBalance,
    CardTotalOrderThisMonth,
    CardTotalCODSuccessThisMonth,
    CardTotalBill,
    ChartSales,
    Carousel,
    OverviewBoxSaldo,
    OverviewBoxOrder
  },
  data() {
    return {
      image2,
      image1,
      image3,

      is_admin: true,
      clickable: false,
    };
  },
  methods: {
    handleviewdetailpackage() {
      console.log('gandle')
    }
  }
};
</script>